import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import ScrollProgressRead from "react-scroll-progress-read"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import AuthorInfo from "../../components/author-info/author-info"

import ServicesList from "../../components/services-list/services-list"
import BlogList from "../../components/blog-list/blog-list"

import "../../styles/blog.scss"

const AdministratorPage = () => {
  const imageArticle = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "blog-curse-of-the-nagas.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="The Curse of the Nagas – Astrologer Aravind | Ashtamangalaprasnam Blog"
        description="Nagas are the most divine creatures according to Hinduism. Are the stories about their divinity and strength of their curse just another myth?"
        keyword="sarpa, dosha, sarppa dosham, nagadosham, sarppadosham, naga curse, hinduism, nagadeva, naga"
        image="../../images/blog-curse-of-the-nagas.jpg"
      />
      <div className="scroll-progress">
        <ScrollProgressRead
          backgroundColor="#CCC"
          barColor="#931a1a"
          height="5px"
          target="article"
        />
      </div>
      <section id="article" className="article">
        <section className="article-header">
          <Link
            className="button article-header__button malayalam"
            to="../curse-of-the-nagas-malayalam"
            title="സർപ്പശാപ ദുരിതമെങ്ങിനെ അകറ്റാം?"
          >
            മലയാളം
          </Link>
          <Image
            className="article-header__image"
            fluid={imageArticle.file.childImageSharp.fluid}
            alt="Naga idols in Sarppakkavu"
          />
          <div className="article-header__text-wrap">
            <h1 className="article__heading">The Curse of the Nagas</h1>
            <p className="article__description">
              Nagas are the most divine creatures according to Hinduism. Are the
              stories about their divinity and strength of their curse just
              another myth?
            </p>
          </div>
        </section>
        <section className="container article-wrap">
          <article className="article__content">
            <p>The Curses can be quite many. Quoting an old Sloka,</p>

            <blockquote>
              “Gurudeva dwijah athyugrah
              <br />
              Sarpashapapicharatha
              <br />
              Jathakam Bhidyathe nyoonam
              <br />
              No chethathal prathikriya”
            </blockquote>

            <p>
              Guru (Teacher), “Deva” (God), “Brahmana” (Priest), Black Magic,
              “Sarpashapa” (Naga curse) will make the one having them not
              experience any good tidings upon themselves. Among these, the
              Curse of the “Nagas” is the most potent.
            </p>

            <p>
              The “Nagas” have always played an important role in the Indian
              Culture. Humans always get to dream about reaching highest tiers
              in their life, style etc. and this dream or thought represented by
              “Nagas”.
            </p>

            <p>
              The reader must understand this before proceeding any further that
              is, <em>All “Nagas” are snakes but all snakes are not “Nagas”</em>
              .
            </p>

            <p>
              The westerners consider “Nagas” as enemies since the first woman
              of the universe, Eve was influenced to eat the fruit of evil by
              none other than the Naga as per their belief. Adam and Eve didn't
              have the relationship of a couple before. The seed of humanity was
              planted then. If God is the one who had created life on earth, the
              prospering of such life was brought about by none other than the
              Naga. If we are to consider that, Naga is a servant of God. The
              ones who are ignorant about the basics of life considers Naga as
              the pawn of the devil. The culture of ancient India aka “Bharatha”
              considered “Nagas” as heavenly hence we can see the sculptures
              depicting “Nagas” in ancient monuments and the likes. The “Nagas”
              have played an important role in the evolution of humans and hence
              they can't be considered as a mere pawn of the devil. If God
              hadn't willed that the humanity should prosper, he wouldn't have
              let Eve be influenced by the Naga and thus if the life is the
              creation of God, “Nagas” just carried out his will.
            </p>

            <p>
              The earth inhabited by the “Nagas” were lent to the humans with
              certain norms as per the request of Lord “Parasurama” which were
              worshipped as the “Sarppakkavu” and the likes. This had been
              acting as an energy source for seeding the generations till now.
              The ones who are having issues of progeny may be part of the
              family who might've destroyed or neglected such sacred places of
              worship.
            </p>
            <p>How the “Nagas” curse?</p>

            <p>
              Curse is sort of like a reaction. Including humans, all of the
              living beings has this reaction. Like for example, if somebody
              makes our mind hurt so much it would lead to setting up a
              reactionary force in our mind which may end up harming the other
              party who has hurt us.
            </p>

            <p>
              Does “Nagas” have a mind like humans? It’s something beyond me to
              confirm. I have lots of "kavus" in my hometown and associated
              worships and not once did the presence of “Nagas” did cause any
              hindrance in any manner. And if one were to wish strongly, the
              “Nagas” can be seen.
            </p>

            <p>
              The dominance of earth itself is in the hands of the “Nagas” as
              per various books. Maybe that's why the karmas involved in the
              establishment of cornerstones in a construction involves
              worshipping the Naga gods. The “Naga” worship is different from
              the normal worships to Gods. “Naga” Gods have come to many a
              family who has been righteous. One thing is certain, the ones who
              had neglected the worshipping of the mentioned had come to
              misfortunes. And I've seen many an unfortunate family whose
              generations were cursed owing to this.
            </p>

            <p>
              I've been asked by most as to whether they have to worship the
              places who wasn't established by them. The answer to this is yes.
              Supposedly an owner of a place is having a “Naga”, a cat, a dog
              and an elephant. Upon him leaving that place, the elephant and the
              dog will follow since they maintain close relationship with the
              owner and the cat and the Naga would be staying since they are
              ones who maintains close relationship with the place rather. And
              it is said that the almighty had given them the powers to curse as
              they are the symbols for the Anger and Wish.
            </p>

            <p>
              The “Naga” related matter in the horoscope had always sent me back
              to the past to an unbelievable worship history in relation to
              that. And whenever I went after the same owing to my curiosity it
              had always led me to the sight of old prestigious worship places
              which had been long abandoned. I'm sure the powers which lead me
              there are none other than the “Naga” Gods. The “Naga” related
              matter in the horoscope had always sent me back to the past to an
              unbelievable worship history in relation to that. And whenever I
              went after the same owing to my curiosity it had always led me to
              the sight of old prestigious worship places which had been long
              abandoned. I'm sure the powers which lead me there are none other
              than the “Naga” Gods. The “Naga” related matter in the horoscope
              had always sent me back to the past to an unbelievable worship
              history in relation to that. And whenever I went after the same
              owing to my curiosity it had always led me to the sight of old
              prestigious worship places which had been long abandoned. I'm sure
              the powers which lead me there are none other than the “Naga”
              Gods.
            </p>

            <p>
              In the horoscopes, the Houses 6, 8 and 12 are said to be the bad
              ones (Dusthanam). The Naga in the Houses 6 and 12 shows the curse.
              That curse should be determined and provisions for resolving it
              can be set up using "Prashnamargam" and "Kavidi" (Horary
              Astrology). Those having the Sarppadosham may have diseases like
              Infertility, Psoriasis, Vitiligo, Eczema and other skin diseases.
              If the medicines aren't resolving the same, an astrologer should
              be consulted and the resolving means as prescribed. In the subject
              of the pregnancy, the "Beejadosham" and the "Kshethtradosham" are
              important. If we are to delve deep into the reasons behind those,
              we'd find the "Sarppadosham as invisible reason behind. The women
              having the Sarppadosham would be having various ailments related
              to the abdomen, Uterus and even the menstrual cycle. Reading the
              above, we can see how much affect the curse will have upon people
              and as to how serious it is.
            </p>

            <p>
              Rahu on 4th House will make the affected experience loss of higher
              studies, the rotting of character. The controller of mind is the
              moon. If the Naga is close to the moon, the affected will be
              thinking in twisted ways. The upside down thoughts,
              rebelliousness, illogical thoughts etc. will be the
              characteristics of the affected. The affected will thus bring not
              only themselves towards ruination but the people who gets to be
              associated with them too.
            </p>
            <p>
              Like the norms of not constructing homes in specific sides of the
              "Devaprathistas", the 4 sides of the "Nagas" shouldn't have any
              construction within a certain range. For the "Nagas" have eyes in
              the 4 directions. Unlike other Hindu Idols the Idols of Naga
              shouldn’t be fixed to the base using Ashtabandha (a binder
              substance). The "Nagas" are the symbol of divinity. And exactly
              owing to this that the "Naga" get to be the necklace of "Mahadeva"
              the Lord of Gods. If anyone is to shame such either knowingly or
              unknowingly, "Sarppakopam" will be affecting them. The life is
              based on the habits. And the role of the "Nagas" in that is big.
              Owing to that, pray well to the almighty for not having the
              "Sarppakopam" for that is a curse which tend to follow
              generations. If the Rahu is in the 7th "Bhava", it seemingly shows
              the accidental death of the affected in the past life. If the Rahu
              is at the "Bhadha" state, "Sarppadosham" is confirmed.
            </p>

            <p>
              In a horoscope, 5th "Bhava" is used for thinking about the past
              lives and the positions. If the Rahu is positioned at this "Bhava"
              the postion of "Rahu" in the past life should be at the "Lagna".
              The solutions should be determined after confirming the state of
              the "Rahu". If the "Rahu" has relationship with Jupiter it is said
              to be the "UthamaNaga (Maninagam)", or if it has relationship with
              Moon then it is "AdhamaNagam (Karinagam)", or if the relationship
              is with Saturn, it is “Kallurutti Sarppam”, and If Venus, then it
              is “PulluvaNaga”, and if Sun, then it is “Nagaraja”, and if the
              “Rahu” gets the full connection with the Moon and Mars, it is
              “Nagayakshi” etc, are the various ordained curses and once
              confirmed, the solutions for the same should be carried along with
              “Nagabali”, “Palppayasahomam”, Rahu's Namaskara Manthra "Om
              kayanaschithra aa bhuva bhoothi sadavridha..." should be prayed 8
              times kneeling down on the north-east side. For “UthamaNaga
              dosha”, the golden sculpture of the Naga and the eggs are the
              solution. To decide the number, an astrologer should be consulted.
            </p>

            <p>
              If the “Rahu” is in the 4th house, The “Chithrakuda” should be
              made and the associated Karmas are to be done. If the “Rahu” is at
              the “Lagna” or 7th house, "Noorumpalum" Vazhipadu should be
              carried out. For the “Nagadosha” at the 12th house, “Pulluvan
              pattu”, The “Sarppam thullal” after drawing the “kalam” etc.
              should be done.
            </p>

            <p>
              Various activities like, shaming, destruction of the
              “Sarppakkavus” by subsidiary activities like cutting the trees,
              the destruction of “sarppaputtu” (Snake Hillock), destruction of
              its eggs, the killing of the “Nagas” etc are the main reasons for
              the anger of the same. The pinpointing of the exact “dosha” can
              only be determined by a competent horary (Kavadi) astrologer. If
              all the “Papa grahas” are in the center, it is called “Nagayoga”
              and the one having this “yoga” will be poor, with health issues,
              without a good character and the likes. If the property for
              construction is to be divided into 9, the 5th one is said to be
              the "Sarppaveethi" and the house coming in this will lead to the
              "Nagabhaya". If one were to observe the ones born in the
              Sarppadrekkana, amazing things can be learnt. (Sarppadrekkanam -
              Vrishchikam – 1st Drekkanam, Karkkidakam – 2nd Drekkanam, Meenam –
              3rd Drekkanam). Sarppathapam, manu janmam etc are acknowledgeable
              things and is under the divine will. Like the Naga coming for us
              after centuries - Naga: Sheerithe" meaning the Naga slithers or
              flies.
            </p>

            <p>
              Our earth rests on the thousand headed Anantha who has unlimited
              prowess and is omnipresent as the power waves. The mutual
              attraction between the planets, the strength of nature, the
              humans, the powers of sound, energy, the divine prowess, the
              powers of “deva” sculptures etc. are flowing around as waves. The
              power waves from the “deva” sculptures comes to the faithful like
              a slithering “Naga”. The waves of power acts on the universe quite
              chaotically and keeps mutual attractions between everything in the
              universe. Our earth stands at the head of those waves like others.
              Owing to this, the universe gets to be stable forever. If not for
              this, the universe won't be existing even. The old sages of
              “Bharatha” understood all these. The humans worship this power as
              God. In the borders of temples, the “kavus” and the “Naga devas”
              are present. In the old settlements, the ponds and “kavus” synced
              with nature were available. The Old kerala traditions, the smell
              of soil which was brought about beautifully by the
              “Nagathanpattus”, “Sarppam thullal” etc are old nostalgic memories
              except for the rare occurrences in the village areas. For the
              stability of nature, the “Kavus” and the ponds are a necessity.
              And they are a beauty to behold too.
            </p>

            <p>
              The thoughts of Gods and associated by the creative minds and
              thoughts had always given beauty to the Kerala and it’s traditions
              and even the whole of “Bharatha” in itself. Our ancestral sages
              had a close relationship with nature where they loved and
              worshipped the same under divinity. And it’s high time that we get
              to follow that path of being one with the nature respecting the
              same as it is.
            </p>

            <p>
              If you have any queries{" "}
              <Link
                to="/#contact-us"
                title="Get in touch with us"
                className="link_text"
              >
                click here
              </Link>{" "}
              to contact us.
            </p>
          </article>

          <aside className="article-sidebar">
            <AuthorInfo></AuthorInfo>
          </aside>
        </section>
      </section>
      <ServicesList cssClass="blog" />
      <BlogList cssClass="blog" />
    </Layout>
  )
}

export default AdministratorPage
